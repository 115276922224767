<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="相册列表"/>
		<div class="tip">
			<p>1.您总共可创建{{albumData.max_album}}个相册、上传{{albumData.max_pic}}张图片，当前还可创建{{albumData.max_album-albumData.list.length}}个相册、上传{{albumData.max_pic-albumData.pic_count}}张照片。成长等级不同，可上传的图片张数也不同。<a
					:href="$store.state.ROOT_URL+'/vip'">点此查看各成长等级权益</a></p>
			<p>2. 图片支持jpg、jpeg、png格式，单张大小不超过10M。</p>
			<p>3. 当上传的图片宽度超过1000像素时，系统会自动对图片进行等比例压缩。</p>
		</div>
		<div class="cont">
			<div class="c-title">
				<button type="button" @click="isFileDiaShow=true">上传图片</button>
			</div>
			<ul class="list">
				<li>
					<div class="add" @click="handleAddAlbum"></div>
					<p>新建相册</p>
				</li>
				<li v-for="(item,index) in albumData.list" :key="item.id" @click="handleNav(item.id)">
					<div class="image-wrap">
						<el-image :src="$store.state.IMG_URL+'/'+item.thumb">
							<div slot="error" class="image-slot">
								<i class="el-icon-picture-outline"></i>
							</div>
						</el-image>
						<div class="image-edit">
							<div class="image-edit-btn-wrap">
								<el-button size="mini" class="image-edit-btn" icon="el-icon-menu" />
								<transition name="fade">
									<ul class="image-edit-nav">
										<li @click.stop="handleEditName(index)"><i class="el-icon-edit"></i>修改名称</li>
										<li @click.stop="handleGetImgList(index)"><i
												class="el-icon-picture-outline-round"></i>设置封面</li>
										<li @click.stop="handleDelAlbum(index)"><i class="el-icon-delete"></i>删除相册</li>
									</ul>
								</transition>
							</div>
						</div>
					</div>
					<p>
						<span>{{item.title}}</span>
						<i>({{item.pic_count}})</i>
					</p>
				</li>
			</ul>
		</div>
		<el-dialog title="设置封面" :visible.sync="coverShow" width="42%">
			<ul class="img-list">
				<li v-for="item in imgList" :key="item.pic_id" @click="handleSetCover(item.pic_id,item.thumb)">
					<img :src="$store.state.IMG_URL+'/'+item.thumb" alt="">
					<p>{{item.title}}</p>
				</li>
			</ul>
			<div class="img-list-page">
				<el-pagination background layout="prev, pager, next" :total="count" :current-page="curPage"
					@current-change="currentChange" hide-on-single-page />
			</div>
		</el-dialog>
		<fileDialog :albumData="albumData" :show="isFileDiaShow" :close.sync="isFileDiaShow" @eventSubmit="getList"
			active="2" />
	</div>
</template>

<script>
	import fileDialog from '@/components/plug/fileDialog'
	export default {
		data() {
			return {
				coverShow: false,
				imgList: [],
				count: 0,
				curPage: 1,
				curIndex: 0,
				albumData: {
					list: []
				},
				isFileDiaShow: false
			}
		},
		created() {
			this.getList()
		},
		methods: {
			getList() {
				this.$axios.post('/albumList').then(data => {
					this.albumData = data
					
				})
			},
			handleAddAlbum() {
				if(this.albumData.list.length < 10){
					this.$prompt('请输入相册名称', '新建相册', {
						closeOnClickModal: false,
						inputValidator(val) {
							if (!val) {
								return '请填写相册名称'
							}
						}
					}).then(({
						value
					}) => {
						this.$axios.post('/addAlbum',{
							title:value,
							}).then(data => {
							if (data.code == 41002) {
								this.$message.error('添加失败')
								return
							}
							this.$message.success('添加成功')
							this.getList()
						})
					}).catch(() => {})
				}else{
					this.$alert('您最多创建10个相册', '提示', {
						  confirmButtonText: '确定',
						  callback: action => {
							  
						  }
					});
				}
			},
			handleEditName(i) {
				this.$prompt('请输入新名称', '修改名称', {
					inputValue: this.albumData.list[i].title,
					closeOnClickModal: false,
				}).then(({
					value
				}) => {
					this.$axios.post('/editAlbumTitle', {
						title: value,
						album_id: this.albumData.list[i].id
					}).then(data => {
						if (data.code == 41002) {
							this.$message.error('修改失败')
							return
						}
						this.$message.success('修改成功')
						this.albumData.list[i].title = value
					})
				}).catch(() => {})
			},
			handleDelAlbum(i) {
				this.$confirm('此操作将永久删除该相册, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if (this.albumData.list.length < 2) {
						this.$message.warning('删除失败，至少保留一个相册')
						return
					}
					this.$axios.post('/delAlbum', {
						album_id: this.albumData.list[i].id
					}).then(data => {
						if (data.code == 41002) {
							this.$message.error('删除失败')
							return
						}
						this.$message.success('删除成功')
						this.albumData.list.splice(i, 1)
					})
				}).catch(() => {})
			},
			handleGetImgList(i) {
				this.curIndex = i
				this.curPage = 1
				this.getImgListData()
			},
			handleSetCover(pic_id, thumb) {
				this.$axios.post('/setAlbumThumb', {
					album_id: this.albumData.list[this.curIndex].id,
					pic_id
				}).then(data => {
					if (data.code == 41002) {
						this.$message.error('设置失败')
						return
					}
					this.$message.success('设置成功')
					this.coverShow = false
					this.albumData.list[this.curIndex].thumb = thumb
				})
			},
			currentChange(cur) {
				this.curPage = cur
				this.getImgListData()
			},
			getImgListData(i) {
				this.$axios.post('/albumPicList', {
					album_id: this.albumData.list[this.curIndex].id,
					page: this.curPage,
					limit: 10
				}).then(data => {
					if (data.code == 41002) {
						this.$message.error('获取失败')
						return
					}
					if (data.list.length) {
						this.coverShow = true
						this.imgList = data.list
						this.count = data.count
					} else {
						this.$message('该相册下暂无相片');
					}
				})
			},
			handleNav(id) {
				this.$router.push(`/album/list?id=${id}`)
			}
		},
		components: {
			fileDialog
		}
	}
</script>

<style scoped>
	.o-wrap{
		width: 1600px;
		background-color: #fff;
	}
	.c-cont {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
	.c-title {
		margin: 30px 0 30px 20px;
	}
	.c-title button {
		height: 35px;
		line-height: 35px;
		background-color: #0059d6;
		color: #fff;
		border: 1px solid transparent;
		outline: none;
		padding: 0px 10px;
	}

	.list {
		margin-top: 20px;
		display: flex;
		flex-wrap: wrap;
	}

	.list>li {
		margin: 0 20px 20px 0;
		width: 146px;
		height: 180px;
		padding: 10px;
		background: url(../../assets/img/pic.png);
		background-size: 100% 100%;
		cursor: pointer;
	}

	.add {
		background: url(../../assets/img/build.png) no-repeat center #f3f3f3;
		background-size: 46px 46px;
		height: 146px;
	}

	.list p {
		display: flex;
		height: 30px;
		align-items: center;
		color: #333;
	}

	.list p span {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.list p i {
		font-style: normal;
		flex: none;
	}

	.image-wrap {
		position: relative;
		height: 146px;
	}

	.image-wrap:hover .image-edit {
		background: rgba(0, 0, 0, .5);
	}

	.image-edit {
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0);
		top: 0;
		left: 0;
		transition: background .3s ease-in-out;
	}

	.el-image {
		width: 100%;
		height: 100%;
	}

	.el-image>>>.image-slot {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #DCDFE6;
	}

	.image-slot i {
		font-size: 30px;
	}

	.tip {
		width: 1510px;
		margin: 10px auto;
		padding: 12px 30px;
		background: #ecf4ff;
		color: #696969;
		line-height: 30px;
		font-size: 14px;
	}

	.img-list {
		display: flex;
		flex-wrap: wrap;
		margin-left: -20px;
	}

	.img-list li {
		width: 132px;
		border: 1px solid #ccc;
		margin-left: 20px;
		margin-bottom: 20px;
	}

	.img-list img {
		width: 132px;
		height: 132px;
	}

	.img-list p {
		background: #f6f6f6;
		color: #333;
		line-height: 30px;
		padding: 0 10px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.img-list-page {
		text-align: center;
		padding-top: 10px;
	}

	.image-edit-btn-wrap {
		position: absolute;
		right: 10px;
		top: 10px;
		opacity: 0;
		transition: opacity .3s ease-in-out;
	}

	.image-wrap:hover .image-edit-btn-wrap {
		opacity: 1;
	}

	.image-edit-btn-wrap:hover .image-edit-nav {
		display: block;
	}

	.image-edit-nav {
		background: #fff;
		position: absolute;
		padding: 10px 0;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		left: 50%;
		transform: translateX(-50%);
		display: none;
	}

	.image-edit-nav li {
		white-space: nowrap;
		height: 30px;
		line-height: 30px;
		padding: 0 10px;
	}

	.image-edit-nav li:hover {
		background: #ecf5ff;
		color: #66b1ff;
	}

	.image-edit-nav i {
		margin-right: 5px;
	}
	.cont{
		width: 1580px;
		margin: 10px auto;
	}
</style>
